<template>
  <v-container
    class="pnf d-flex align-center justify-center flex-column pt-5 pb-5"
  >
    <div class="pnf_title mb-3">404</div>
    <div class="pnf_subtitle mb-4">Сторiнки не знайдено</div>
    <router-link to="/" class="btn">Додому</router-link>
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>
